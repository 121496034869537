import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Accelerated onboarding helps HSBC UK hit broker milestone</h1>
    <p>HSBC UK has hit and surpassed the milestone of 300 broker partnerships, the bank announced today, an increase of 126 brokers since the start of the pandemic and the first lockdown.</p>
    <p>With the addition of three more firms now with access to HSBC UK’s mortgage products, the total number of broker partners is now 302.</p>
    <p>The additions to HSBC UK’s panel are Andrew Nolan Mortgage Ltd, John Earnshaw Independent Financial Advisers and Salus Mortgage Solutions.</p>
    <p>
      Chris Pearson, HSBC UK’s Head of Intermediary Mortgages, said: “I am extremely proud to reach this milestone, especially at such a challenging time. We have maintained a high level of support to our broker partners throughout these testing times, and stand ready to support them going forward.
      <br/>
      “We have accelerated our broker onboarding and have added 126 more during the pandemic, which is a fantastic achievement. We hope to continue at this rapid pace and expect to add significantly more broker firms to our panel, helping more people onto or up the property ladder.”
    </p>
  </NewsArticle>
)

export default Article
